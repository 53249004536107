import { useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { requestSubscription, OperationType, GraphQLSubscriptionConfig } from 'relay-runtime';

export function useSubscriptionSkippable<TSubscriptionPayload extends OperationType>(
    config: GraphQLSubscriptionConfig<TSubscriptionPayload>,
    skip: boolean
): void {
    const environment = useRelayEnvironment();

    useEffect(() => {
        if (skip) {
            return () => {};
        }
        const { dispose } = requestSubscription(environment, config);
        return dispose;
    }, [environment, config, skip]);
}
